const initialState = {
  showMobileNav: false
}

const navReducer = (state = initialState, action) => {
  switch(action.type) {
    case "TOGGLE_MOBILE_NAV":
      return {
        ...state,
        showMobileNav: action.payload
      }
  }
  return state
}

export default navReducer
