/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export { default as wrapRootElement } from "./src/redux/reduxWrapper"

let query = ""

export const onClientEntry = () => {
  query = location ? location.search : undefined
}

const insertJS = () => {
  const addPardotJS = (src) => {
    const s = document.createElement(`script`)
    s.type = `text/javascript`
    s.src = src
    document.getElementsByTagName(`head`)[0].appendChild(s)
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    originalQuery: location && location.search !== query ? location.href + query : location.href
  })

  addPardotJS("https://pi.pardot.com/pd.js")
}

export const onRouteUpdate = () => {
  insertJS()
}
