const initialState = {
  allPartners: [],
  filteredPartners: [],
  filters: {
    partnerType: "all",
    product: "all",
    services: "all"
  }
}

const partnersReducer = (state = initialState, action) => {
  switch(action.type) {
    case "FETCH_PARTNERS":
      return {
        ...state,
        allPartners: action.payload,
        filteredPartners: action.payload,
      }

    case "UPDATE_FILTER":
      return {
        ...state,
        filters: { ...action.filters }
      }

    case "UPDATE_PARTNERS":
      return {
        ...state,
        filteredPartners: action.filteredPartners
      }

    default: return state
  }
}

export default partnersReducer
