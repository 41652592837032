const initialState = {
  showModal: false,
  modalType: null,
  url: null,
  image: null,
  name: null,
  position: null,
  bio: null,
  link: null,
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_VIDEO_MODAL":
      return {
        ...state,
        showModal: action.payload.isOpen,
        modalType: "video",
        url: action.payload.url,
      }
    case "SHOW_PROFILE_MODAL":
      return {
        ...state,
        showModal: action.payload.isOpen,
        modalType: "profile",
        image: action.payload.image,
        name: action.payload.name,
        position: action.payload.position,
        bio: action.payload.bio,
      }
    case "SHOW_PARTNER_MODAL":
      return {
        ...state,
        showModal: action.payload.isOpen,
        modalType: "partner",
        url: action.payload.url,
        image: action.payload.image,
        name: action.payload.name,
        position: action.payload.position,
        bio: action.payload.bio,
      }
    case "SHOW_IFRAME_MODAL":
      return {
        ...state,
        showModal: action.payload.isOpen,
        url: action.payload.url,
        modalType: "iframe",
        formLink: action.payload.formLink,
        formTitle: action.payload.formTitle,
        formSubtitle: action.payload.formSubtitle,
      }
    case "CLOSE_MODAL":
      return {
        ...state,
        showModal: false,
        modalType: null,
        image: null,
        name: null,
        position: null,
        bio: null,
      }
    default:
      return state
  }
}

export default modalReducer
