import { combineReducers } from "redux"
import modal from "./modalReducer"
import nav from "./navReducer"
import partnerFilter from "./partnerFilterReducer"
import cookie from "./cookieReducer"

const Reducers = combineReducers({
  modal,
  nav,
  partnerFilter,
  cookie
})

export default Reducers
