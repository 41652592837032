const initialState = {
  cookiesAccepted_pf: false,
}

const cookieReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACCEPT_COOKIES":
      return {
        ...state,
        cookiesAccepted_pf: action.accepted,
      }

    case "REMOVE_COOKIES":
      return {
        ...state,
        cookiesAccepted_pf: action.accepted,
      }

    case "CHECK_COOKIES":
      return {
        ...state,
        cookiesAccepted_pf: action.accepted,
      }

    default:
      return state
  }
}

export default cookieReducer
